<template>
  <div>
    <!-- Filters -->
    <users-list-filters
      :username-filter.sync="usernameFilter"
      :phone-filter.sync="phoneFilter"
      :ip-filter.sync="ipFilter"
      :name-filter.sync="nameFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
      :email-filter.sync="emailFilter"
      :status-filter.sync="statusFilter"
      :is-kyc-filter.sync="isKycFilter"
      :referral-code-filter.sync="referralCodeFilter"
      :affiliate-id-filter.sync="affiliateIdFilter"
      :status-options="userStatusOptions"
      :kyc-options="kycOptions"
      :handle-search.sync="fetchUsers"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <div v-if="$can('create', 'user')">
              <b-button
                variant="primary"
                class="ml-2"
                style="
                  padding: 12px;
                  border-radius: 6px;
                  background: rgb(60, 194, 187);
                  cursor: pointer;
                "
                @click="goToAddUser"
              >
                <span class="text-nowrap" style="padding: 12px; color: white">
                  <feather-icon icon="PlusIcon" /> New
                </span>
              </b-button>
              </div>
              <div style="margin-left: 10px" v-if="$can('export', 'user')">
              <b-button
                  v-if="!showLoadingExport"
                  variant="primary"
                  @click="exportData"
              >
                <feather-icon icon="DownloadIcon" size="16" /> {{ $t("Export") }}
              </b-button>
              <b-button v-else variant="primary" disabled class="mr-1">
                <b-spinner small />
                <feather-icon icon="DownloadIcon" size="16" />
                {{ $t("Exporting") }}
              </b-button>
              <div class="hidden">
                <vue-export-excel
                    ref="exportButton"
                    :data="dataExport"
                    :columns="columns"
                    :file-name="exportFiletitle"
                    :file-type="'xlsx'"
                    :sheet-name="exportFileSheetName"
                >
                  <feather-icon icon="DownloadIcon" size="16" />
                  {{ $t("Export") }}
                </vue-export-excel>
              </div>
            </div>
            </div>
          </b-col>
<!--          <b-col cols="6" md="3" lg="2" sm="4" v-if="$can('export', 'user')">-->
<!--            <div class="d-flex align-items-center justify-content-end">-->
<!--            </div>-->
<!--          </b-col>-->
        </b-row>
      </div>
      <b-table
        ref="refUserListTable"
        class="position-relative table-white-space max-height-table"
        sticky-header
        head-variant="light"
        :no-border-collapse="true"
        :items="listUser"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :busy="loading"
      >
        <div slot="table-busy" class="text-center mt-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-link
            v-if="$can('view', 'userdetail')"
            :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
          >
            {{ data.item.username }}
          </b-link>
          <span v-else class="text-primary">
            {{ data.item.username }}
          </span>
        </template>

        <template #cell(name)="data">
          <span v-if="data.item.is_audit == '1'" style="color: orange">
            {{ data.item.name }}
          </span>
          <span v-else>
            {{ data.item.name }}
          </span>
        </template>

        <template #cell(balance)="data">
          <span
            class="text-primary"
            >{{ ...data.item.balances.map(item => numberFormat(item.value / 1000)) }}</span
          >
        </template>

        <template #cell(outStandingBalance)="data">
          <span class="text-primary">
            {{
              data.item.txn_dtos_sum_amount != null
                ? numberFormat(data.item.txn_dtos_sum_amount / 1000)
                : 0
            }}
          </span>
        </template>
        <template #cell(sum_deposits)="data">
          <span class="text-primary">
            {{
              data.item.sum_deposits != null
                ? numberFormat(data.item.sum_deposits / 1000)
                : 0
            }}
          </span>
        </template>
        <template #cell(winLoss)="data">
          <span v-if="data.item.winLoss >= 0" class="text-primary">
            {{ numberFormat(data.item.winLoss) }}
          </span>
          <span v-else class="text-danger">
            {{ numberFormat(data.item.winLoss) }}
          </span>
        </template>

        <template #cell(createdAt)="data">
          {{ formatDateTime(data.item.created_at).replace(".000000Z", "") }}
        </template>

        <template #cell(latest_login)="data">
					{{ data.item.latest_login ? displayLoginDuration(formatDateTime(data.item.latest_login.created_at).replace('.000000Z', '')) : '' }}
				</template>

        <template #cell(currency)="data">
          {{ data.item.currency ? data.item.currency : "VND" }}
        </template>

        <template #cell(affiliate_id)="data">
          {{ data.item.affiliate_id }}
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <div
            v-if="$can('update', 'userstatus')"
            :class="`text-capitalize align-items-center d-flex cursor-pointer`"
            @click="showModalUpdateStatus(data.item.id)"
          >
            <span
              :class="`dot bg-${resolveUserStatus(data.item.stat).variant}`"
            />{{ resolveUserStatus(data.item.stat).label }}
          </div>
          <div v-else :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveUserStatus(data.item.stat).variant}`"
            />{{ resolveUserStatus(data.item.stat).label }}
          </div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <!-- Dropdown -->
            <b-dropdown
              variant="link"
              toggle-class="p-0"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >
              <template #button-content>
                <feather-icon
                  icon="MoreHorizontalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>
              <b-dropdown-item
                v-if="$can('view', 'userdetail')"
                :to="{ name: 'apps-users-view', params: { id: data.item.id } }"
              >
                <feather-icon icon="EyeIcon" />
                <span class="align-middle ml-50">{{ $t("View") }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$can('change', 'userpassword')"
                @click="showModalUpdatePassword(data.item.id)"
              >
                <feather-icon icon="RefreshCwIcon" />
                <span class="align-middle ml-50">{{
                  $t("Reset Password")
                }}</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="$can('read', 'callcenter')"
                @click="toCallCenter(data.item.id)"
              >
                <feather-icon icon="PhoneCallIcon" />
                <span class="align-middle ml-50">{{ $t("Call Center") }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <!-- Model Change User Password -->
    <user-detail-change-password-modal :user-data="userData" />
    <user-detail-change-status-modal
      :user-data="userData"
      :status-update.sync="statusUpdate"
      @refetch-data="refetchData"
    />
    <AddUserListModal
      :user-detail.sync="userDetail"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBTooltip, BSpinner,
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import {
  avatarText,
  formatDateTime,
  formatRemoveTime,
  numberFormat,
  resolveCurrency,
} from "@core/utils/filter";
import store from "@/store";
import roleStoreModule from "@/views/local-setting/local/roleStoreModule";
import UserDetailChangePasswordModal from "@/views/apps/user/users-detail/UserDetailChangePasswordModal.vue";
import UserDetailChangeStatusModal from "@/views/apps/user/users-detail/UserDetailChangeStatusModal.vue";
import UsersListFilters from "./UsersListFilters.vue";
import useUsersList from "./useUsersList";
import AddUserListModal from "./AddUserListModal.vue";
import userStoreModule from "../userStoreModule";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VueExportExcel from "@/views/extensions/export/VueExportExcel";
import i18n from "@/libs/i18n";

export default {
  components: {
    AddUserListModal,
    goToAddUser: () => import("./AddUserListModal.vue"),
    UsersListFilters,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    UserDetailChangePasswordModal,
    UserDetailChangeStatusModal,
    VueExportExcel
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      userData: {
        username: "",
        id: "",
        stat: null,
      },
      userDetail: null,
      columns: [
        {field: "id", label: "ID"},
        {field: "username", label: i18n.t("Login Name")},
        {field: "name", label: i18n.t("Full Name")},
        {field: "email", label: i18n.t("Email")},
        {field: "phone", label: i18n.t("Phone")},
        {field: "affiliate", label: i18n.t("Affiliate")},
        {field: "referral_code", label: i18n.t("Referral Code")},
        {
          field: "level_id",
          label: "Level Id"
        },
        {
          field: "register_at",
          label: i18n.t("Register at")
        },
      ],
      exportFiletitle: `ListUsers-${new Date()
          .toJSON()
          .replace(":", "-")
          .substring(0, 16)}`,
      exportFileSheetName: "ListUsers",
    };
  },
  methods: {
    goToAddUser() {
      this.$bvModal.show("modal-add-user");
      // this.$bvModal.show("modal-update-password");
    },
    showModalUpdatePassword(id) {
      store.dispatch("app-user/fetchUserDetail", id).then((response) => {
        this.userData.id = response.data.data.user.id;
        this.userData.username = response.data.data.user.username;
      });
      this.$bvModal.show("modal-update-password");
    },
    async toCallCenter(id) {
      await store
        .dispatch("app-user/fetchCallCenter", { userId: id })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${response.data.message}`,
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        });
    },
    showModalUpdateStatus(id) {
      store.dispatch("app-user/fetchUserDetail", id).then((response) => {
        console.log('4')
        this.userData.id = response.data.data.user.id;
        this.userData.username = response.data.data.user.username;
        this.userData.stat = response.data.data.user.stat;
      });
      this.$bvModal.show("modal-update-status");
    },
  },
  setup() {
    const showLoadingExport = ref(false);
    const exportButton = ref(null);
    const dataExport = ref([]);
    const USER_APP_STORE_MODULE_NAME = "app-user";
    const LOCAL_SETTING_ROLE_STORE_MODULE_NAME = "local-setting-role";

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
    if (!store.hasModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME))
      store.registerModule(
        LOCAL_SETTING_ROLE_STORE_MODULE_NAME,
        roleStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      // if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
      if (store.hasModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME))
        store.unregisterModule(LOCAL_SETTING_ROLE_STORE_MODULE_NAME);
    });

    const exportData = async () => {
      showLoadingExport.value = true;
      await getExportData();
      console.log('exportButton.value', exportButton.value)
      exportButton.value.$el.click();
    };
    const getExportData = async () => {
      await store
          .dispatch("app-user/exportListUser", {
            username: usernameFilter.value,
            email: emailFilter.value,
            fullname: nameFilter.value,
            phone: phoneFilter.value,
            ip: ipFilter.value,
            level: affIdFilter.value,
            from_date: fromdateFilter.value,
            to_date: todateFilter.value,
            stat: statusFilter.value,
            is_kyc: isKycFilter.value,
            referral_code: referralCodeFilter.value,
            affiliateId: affiliateIdFilter.value,
          })
          .then((response) => {
            if (response.data.code === "00") {
              dataExport.value = response.data.data.map((item, index) => {
                return {
                  ...item,
                  index: index + 1
                }
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
            showLoadingExport.value = false;
          });
    };

    const isAddNewUserSidebarActive = ref(false);

    // eslint-disable-next-line radix
    const sumBy = (items) =>
      items.reduce((n, { net_amount }) => n + parseInt(net_amount), 0);

    const sumArrayOutStandingBalance = (array) => {
      let sum = 0;
      let newArray = array.map(function (val) {
        return Number(val.amount);
      });
      newArray.map(function (value) {
        sum += value;
      });
      return numberFormat(sum / 1000);
    };

    const displayLoginDuration = (previousLoginDate) => {
      const previousLoginTime = new Date(previousLoginDate).getTime();
      const millisecondsInMinute = 1000 * 60;
      const millisecondsInHour = 1000 * 60 * 60;
      const millisecondsInDay = millisecondsInHour * 24;
      const millisecondsInMonth = millisecondsInDay * 30;
      const millisecondsInYear = millisecondsInDay * 365;
    
      const currentTime = new Date().getTime(); // Current time in UTC
      const elapsedTime = currentTime - previousLoginTime;

      if (elapsedTime < millisecondsInHour) {
        const minutes = Math.floor(elapsedTime / millisecondsInMinute);
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
      } else if (elapsedTime < millisecondsInDay) {
        const hours = Math.floor(elapsedTime / millisecondsInHour);
        return `${hours} hour${hours > 1 ? 's' : ''} ago`;
      } else if (elapsedTime < millisecondsInMonth) {
        const days = Math.floor(elapsedTime / millisecondsInDay);
        return `${days} day${days > 1 ? 's' : ''} ago`;
      } else if (elapsedTime < millisecondsInYear) {
        const months = Math.floor(elapsedTime / millisecondsInMonth);
        return `${months} month${months > 1 ? 's' : ''} ago`;
      } else {
        const years = Math.floor(elapsedTime / millisecondsInYear);
        return `Long time ago (${years} year${years > 1 ? 's' : ''})`;
      }
    }

    const {
      fetchUsers,
      listUser,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserStatus,
      resolveUserEmailVerify,

      // Extra Filters
      usernameFilter,
      emailFilter,
      nameFilter,
      fullnameFilter,
      phoneFilter,
      ipFilter,
      affIdFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,
      isKycFilter,
      referralCodeFilter,
      affiliateIdFilter,
      statusUpdate,

      userStatusOptions,
      kycOptions,
      loading
    } = useUsersList();
    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchUsers,
      listUser,
      tableColumns,
      displayLoginDuration,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      referralCodeFilter,

      // Filter
      avatarText,
      formatDateTime,
      formatRemoveTime,
      numberFormat,
      resolveCurrency,

      // UI
      resolveUserStatus,
      resolveUserEmailVerify,
      sumBy,
      sumArrayOutStandingBalance,

      userStatusOptions,
      kycOptions,

      // Extra Filters
      nameFilter,
      usernameFilter,
      emailFilter,
      fullnameFilter,
      phoneFilter,
      ipFilter,
      affIdFilter,
      fromdateFilter,
      todateFilter,
      statusFilter,
      isKycFilter,
      affiliateIdFilter,
      statusUpdate,
      showLoadingExport,
      dataExport,
      exportButton,
      exportData,
      loading
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
